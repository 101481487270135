import React, { useEffect, useState } from 'react';
import {
    AppstoreOutlined,
    AudioOutlined,
    BookOutlined, ClockCircleOutlined,
    FileImageOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, OpenAIOutlined,
    ReadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import { Outlet } from 'react-router-dom';
import Sider from 'antd/es/layout/Sider';
import { Content, Header, Footer } from 'antd/es/layout/layout';
import BrandLogo, {brandLogo} from "./badge/brandLogo";

const items = [
  { label: 'Users', key: '/users', icon: <UserOutlined />, href: '/users' },
  { label: 'User Feedback', key: '/feedback', icon: <MailOutlined />, href: '/feedback' },
  { label: 'GPT Prompt', key: '/prompt', icon: <OpenAIOutlined />, href: '/prompt' },
  { label: 'AI Image', key: '/images', icon: <FileImageOutlined />, href: '/images' },
  { label: 'Audio', key: '/audio', icon: <AudioOutlined />, href: '/audio' },
  { label: 'Schedule', key: '/schedule', icon: <ClockCircleOutlined />, href: '/schedule' },
  { label: 'Books', key: '/books', icon: <ReadOutlined />, href: '/books' },
  { label: 'Words', key: '/words', icon: <BookOutlined />, href: '/words' },
];

const Root = () => {
  const [current, setCurrent] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCurrent(window.location.pathname);
  }, []);

  const onClick = (e) => {
    window.location.href = e.key;
  };

  return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="lg" // Automatically collapses on screens smaller than `lg`
            onBreakpoint={(broken) => setCollapsed(broken)}
            style={{
              position: 'fixed',
              height: '100vh',
              overflow: 'auto',
            }}
        >
            <BrandLogo collapsed={collapsed} />

          <Menu onClick={onClick} selectedKeys={[current]} mode="inline" items={items} theme="dark" />
        </Sider>
        <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Header
              style={{
                padding: 0,
                background: '#ffffff',
                position: 'fixed',
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
          >
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
            />
            <div
                style={{
                  marginRight: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  lineHeight: 1.2, // Adjust line spacing
                }}
            >
              <span style={{fontWeight: 'bold', fontSize: '16px'}}>Roger Yu</span>
              <span style={{color: '#888', fontSize: '14px'}}>yuruojie777@gmail.com</span>
            </div>
          </Header>
          <Content
              style={{
                marginTop: 80,
                marginLeft: 16,
                marginRight: 16,
                padding: 24,
                background: '#ffffff',
                minHeight: 'calc(100vh - 112px)',
              }}
          >
            <Outlet/>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Vocab AI ©{new Date().getFullYear()} Nut AI</Footer>
        </Layout>
      </Layout>
  );
};

export default Root;
