// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ScheduledTaskTable.css */
.updated-row {
  animation: flashGreen 0.5s ease-in-out;
}

@keyframes flashGreen {
  0% {
    background-color: #e0f7e0; /* Light green */
  }
  50% {
    background-color: #e2fae3; /* Darker green */
  }
  100% {
    background-color: white; /* Reset to original background color */
  }
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,2BAA2B;AAC3B;EACE,sCAAsC;AACxC;;AAEA;EACE;IACE,yBAAyB,EAAE,gBAAgB;EAC7C;EACA;IACE,yBAAyB,EAAE,iBAAiB;EAC9C;EACA;IACE,uBAAuB,EAAE,uCAAuC;EAClE;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* ScheduledTaskTable.css */\n.updated-row {\n  animation: flashGreen 0.5s ease-in-out;\n}\n\n@keyframes flashGreen {\n  0% {\n    background-color: #e0f7e0; /* Light green */\n  }\n  50% {\n    background-color: #e2fae3; /* Darker green */\n  }\n  100% {\n    background-color: white; /* Reset to original background color */\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
